<template>
  <div class="form-stepper form-stepper-channel_subscription">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <div v-if="channelItem.id">
        <v-row class="mt-0">
          <v-col cols="12">
            <p class="primary--text">{{ $t('CHANNEL_SUBSCRIPTION.ACCOUNT_TITLE') }}</p>
            <div class="ma-0 d-flex">
              <v-col class="pa-0" cols="12">
                <v-switch class="mt-1"
                  v-model="use_xtramile_account"
                  @change="$emit('input', { use_xtramile_account:$event })"
                  :value="true"
                  :false-value="false"
                  :true-value="true"
                  :label="$t('CHANNEL_SUBSCRIPTION.USE_XTRAMILE_ACCOUNT', { a: channelItem.title })"
                />
              </v-col>
            </div>
            <div :class="[use_xtramile_account ? 'muted' : '']">
              <template v-for="(params, i) in channelItem.account_params">
                <template v-if="params.type === 'boolean'">
                  <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                    <v-col class="pa-0 pt-2" cols="3">
                      <small class="grey--text">{{ params.name }}</small>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-select
                        v-model="account_params[params.name]"
                        @change="$emit('input', { account_params:{ [params.name]:$event }})"
                        :placeholder="params.name"
                        :disabled="use_xtramile_account"
                        :items="$t('CHANNEL_SUBSCRIPTION.BOOLEAN_ITEMS')"
                        outlined
                        dense
                        hide-details
                        :rules="!use_xtramile_account && params.required ? [$rules.required] : [true]"
                        required
                      />
                    </v-col>
                  </div>
                </template>

                <template v-else-if="params.type === 'number'">
                  <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                    <v-col class="pa-0 pt-2" cols="3">
                      <small class="grey--text">{{ params.name }}</small>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-text-field
                        v-model="account_params[params.name]"
                        @change="$emit('input', { account_params:{ [params.name]:$event }})"
                        type="number"
                        min="0"
                        :placeholder="params.name"
                        :disabled="use_xtramile_account"
                        outlined
                        dense
                        hide-details
                        :rules="!use_xtramile_account && params.required ? [$rules.required] : [true]"
                        required
                      />
                    </v-col>
                  </div>
                </template>

                <template v-else>
                  <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                    <v-col class="pa-0 pt-2" cols="3">
                      <small class="grey--text">{{ params.name }}</small>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-text-field
                        v-model="account_params[params.name]"
                        @change="$emit('input', { account_params:{ [params.name]:$event }})"
                        :placeholder="params.name"
                        :disabled="use_xtramile_account"
                        outlined
                        dense
                        hide-details
                        :rules="!use_xtramile_account && params.required ? [$rules.required] : [true]"
                        required
                      />
                    </v-col>
                  </div>
                </template>
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12">
            <p class="primary--text">{{ $t('CHANNEL_SUBSCRIPTION.CHANNEL_PARAMS_TITLE') }}</p>
            <div class="ma-0 d-flex">
              <v-col class="pa-0 pt-2" cols="3">
                <small class="grey--text">{{ $t('CHANNEL_SUBSCRIPTION.APPLY_METHOD') }}</small>
              </v-col>
              <v-col class="pa-0" cols="9">
                <v-select
                  v-model="channel_params.apply_method"
                  :items="$t('CHANNEL_SUBSCRIPTION.APPLY_METHOD_ITEMS')"
                  class="text-nowrap"
                  item-text="text"
                  item-value="value"
                  @change="$emit('input', { channel_params:{ apply_method:$event }})"
                  outlined
                  dense
                />
              </v-col>
            </div>

            <div class="ma-0 d-flex">
              <v-col class="pa-0" cols="12">
                <v-switch
                  class="mt-1"
                  v-model="channel_params.test_mode"
                  @change="$emit('input', { channel_params:{ test_mode:$event }})"
                  :false-value="false"
                  :true-value="true"
                  :value="true"
                  color="warning"
                  :label="$t('CHANNEL_SUBSCRIPTION.TEST_MODE', {a: channelItem.title})"
                />
              </v-col>
            </div>
            <div>
              <template v-for="(params, i) in channelItem.channel_params">
                <template v-if="params.type === 'boolean'">
                  <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                    <v-col class="pa-0 pt-2" cols="3">
                      <small class="grey--text">{{ params.name }}</small>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-switch
                        v-model="channel_params[params.name]"
                        class="mt-2"
                        color="success"
                        :label="activeSwitch"
                        :rules="params.required ? [$rules.required] : [true]"
                        @change="$emit('input', { channel_params:{ [params.name]:$event }})"
                      />
                    </v-col>
                  </div>
                </template>

                <template v-else-if="params.type === 'number'">
                  <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                    <v-col class="pa-0 pt-2" cols="3">
                      <small class="grey--text">{{ params.name }}</small>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-text-field
                        v-model="channel_params[params.name]"
                        @change="$emit('input', { channel_params:{ [params.name]:$event }})"
                        type="number"
                        min="0"
                        :placeholder="params.name"
                        outlined
                        dense
                        hide-details
                        :rules="params.required ? [$rules.required] : [true]"
                        required
                      />
                    </v-col>
                  </div>
                </template>

                <template v-else>
                  <div class="ma-0 mb-1 d-flex" :key="'par'+i">
                    <v-col class="pa-0 pt-2" cols="3">
                      <small class="grey--text">{{ params.name }}</small>
                    </v-col>
                    <v-col class="pa-0" cols="8">
                      <v-text-field
                        v-model="channel_params[params.name]"
                        @change="$emit('input', { channel_params:{ [params.name]:$event }})"
                        :placeholder="params.name"
                        outlined
                        dense
                        hide-details
                        :rules="params.required ? [$rules.required] : [true]"
                        required
                      />
                    </v-col>
                  </div>
                </template>
              </template>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-divider />
    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer />
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'FormChannelSubscriptionStepperIntro',
  data: () => ({
    loading: false,
    valid: false,
    channelItem: {},
    //
    use_xtramile_account: false,
    channel_params: {},
    account_params: {},
    activeSwitch: '',
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    values(n) {
      this.init()
    },

    channel_params: {
      handler(n) {
        const { name } = this.channelItem.channel_params[0]
        this.activeSwitch = this.$t('CHANNEL_SUBSCRIPTION.BOOLEAN_ITEMS')
          .find(item => item.value === n[name]).text
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
    this.loadChannelItem()
  },
  methods: {
    init() {
      Object.keys(this.values)
        .forEach((key) => {
          if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
            this[key] = this.values[key]
          }
        })
    },

    loadChannelItem() {
      return this.$services.api_programmatic.channel
        .search({where:{id:this.values.channel_id}})
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.channelItem = response.data[0]
            this.activeSwitch = this.$t('CHANNEL_SUBSCRIPTION.BOOLEAN_ITEMS')
              .find(item => item.value === false).text
          }
        })
    },

    validate() {
      if (!this.$refs.form.validate()) {
        this.$emit('validation', false)
        return
      }

      const data = {}
      data.channel_id = this.channel_id || undefined
      data.channel_params = this.channel_params
      data.account_params = this.account_params
      data.use_xtramile_account = this.use_xtramile_account || undefined

      this.$emit('childData', data)
      this.$emit('validation', true)
      this.$emit('next')
    },
  },
}
</script>
